var api = require("!../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../node_modules/css-loader/dist/cjs.js??ref--13-1!../node_modules/postcss-loader/src/index.js??embedded!../node_modules/sass-loader/dist/cjs.js??ref--13-3!./project-theme.scss");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);

var exported = content.locals ? content.locals : {};



module.exports = exported;